import { useContext } from "react";
import { ToastContext } from "../context/ToastContext";

export const useToastContext = () => {
    const context = useContext(ToastContext);

    const showInfoToast = (title = "Important notice!", message = "") => {
        context.dispatch({
            type: "ADD",
            payload: {
                content: { sucess: "OK", title: title, message: message },
                type: "info",
            },
        });
    };
    const showSuccessToast = (title = "Successfully saved!", message = "") => {
        context.dispatch({
            type: "ADD",
            payload: {
                content: { sucess: "OK", title: title, message: message },
                type: "success",
            },
        });
    };
    const showErrorToast = (title = "An error occurred", message = "") => {
        context.dispatch({
            type: "ADD",
            payload: {
                content: { sucess: "OK", title: title, message: message },
                type: "error",
            },
        });
    };
    const closeToast = (id) => {
        context.dispatch({ type: "REMOVE", payload: { id } });
    };
    const closeAllToasts = () => {
        context.dispatch({
            type: "REMOVE_ALL",
        });
    };

    if (!context) {
        throw Error(
            "useToastContext must be used inside an ToastContextProvider"
        );
    }

    return {
        ...context,
        showInfoToast,
        showSuccessToast,
        showErrorToast,
        closeToast,
        closeAllToasts,
    };
};
