import { Fragment, useEffect, useRef, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import {
    EmojiHappyIcon,
    EmojiSadIcon,
    FireIcon,
    HeartIcon,
    PaperClipIcon,
    ThumbUpIcon,
    XIcon,
} from "@heroicons/react/solid";

import { useAuthContext } from "../../../hooks/useAuthContext";

import { Listbox, Transition } from "@headlessui/react";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const moods = [
    {
        name: "Excited",
        value: "excited",
        icon: FireIcon,
        iconColor: "text-white",
        bgColor: "bg-red-500",
    },
    {
        name: "Loved",
        value: "loved",
        icon: HeartIcon,
        iconColor: "text-white",
        bgColor: "bg-pink-400",
    },
    {
        name: "Happy",
        value: "happy",
        icon: EmojiHappyIcon,
        iconColor: "text-white",
        bgColor: "bg-green-400",
    },
    {
        name: "Sad",
        value: "sad",
        icon: EmojiSadIcon,
        iconColor: "text-white",
        bgColor: "bg-yellow-400",
    },
    {
        name: "Thumbsy",
        value: "thumbsy",
        icon: ThumbUpIcon,
        iconColor: "text-white",
        bgColor: "bg-blue-500",
    },
    {
        name: "I feel nothing",
        value: null,
        icon: XIcon,
        iconColor: "text-gray-400",
        bgColor: "bg-transparent",
    },
];

const ChatCreate = ({ room, onMessageSent }) => {
    const { addDocument, response } = useFirestore("messages");
    const { user } = useAuthContext();
    const inputRef = useRef();
    const [message, setMessage] = useState("");
    const [selected, setSelected] = useState(moods[5]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        addDocument({
            message,
            photoURL: user.photoURL,
            room: room.id,
        });
    };

    useEffect(() => {
        //* save successfull, show notication
        if (response.success) {
            // emit to parent action complete
            onMessageSent(message);
            setMessage("");
        }
    }, [response]);

    // useEffect(() => {
    //     if (inputRef.current) {
    //         inputRef.current.focus();
    //     }
    // }, [inputRef]);

    return (
        <>
            <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                    <img
                        className="inline-block h-12 w-12 rounded-full"
                        referrerPolicy="no-referrer"
                        src={user.photoURL}
                        alt=""
                    />
                </div>
                <div className="min-w-0 flex-1">
                    <form onSubmit={handleSendMessage} className="relative">
                        <div className="mr-1 mb-1 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                            <label htmlFor="comment" className="sr-only">
                                Add your message
                            </label>
                            <textarea
                                ref={inputRef}
                                rows={3}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                name="comment"
                                id="comment"
                                className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                placeholder="Add your message..."
                            />

                            {/* Spacer element to match the height of the toolbar */}
                            <div className="py-2" aria-hidden="true">
                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                <div className="py-px">
                                    <div className="h-9" />
                                </div>
                            </div>
                        </div>

                        <div className="absolute bottom-0 inset-x-0 pl-3 pr-3 py-2 flex justify-between">
                            <div className="flex items-center space-x-5">
                                <div className="flex items-center">
                                    {/* <button
                                        type="button"
                                        className="-m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500"
                                    >
                                        <PaperClipIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                        <span className="sr-only">
                                            Attach a file
                                        </span>
                                    </button> */}
                                </div>
                            </div>
                            <div className="flex-shrink-0">
                                <button
                                    type="submit"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ChatCreate;
