import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// styles
import "./App.css";

// components
import Navbar from "./components/Navbar";

// pages
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Dashboard from "./pages/dashboard/Dashboard";
import Team from "./pages/team/Team";
import Chat from "./pages/chat/Chat";
import Samples from "./pages/samples/Samples";
import AppLayout from "./components/layouts/page/AppLayout";
import TopNavbar from "./components/TopNavbar";
import UserProfile from "./pages/userprofile/UserProfile";

function App() {
    // authIsReady - Set from AuthContext once Firebase user check complete.
    const { authIsReady, user } = useAuthContext();

    return (
        <div className="App">
            <AppLayout>
                {authIsReady && (
                    <BrowserRouter>
                        {/* {user && <Navbar />} */}
                        {user && <TopNavbar />}
                        <div>
                            {/*OLD!!!  <div className="container max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8"> */}
                            <Switch>
                                <Route exact path="/">
                                    {!user && <Redirect to="/login" />}
                                    {user && <Dashboard />}
                                </Route>
                                <Route exact path="/team">
                                    {!user && <Redirect to="/login" />}
                                    {user && <Team />}
                                </Route>
                                <Route exact path="/chat">
                                    {!user && <Redirect to="/login" />}
                                    {user && <Chat />}
                                </Route>
                                <Route exact path="/samples">
                                    {!user && <Redirect to="/login" />}
                                    {user && <Samples />}
                                </Route>
                                <Route exact path="/profile">
                                    {!user && <Redirect to="/login" />}
                                    {user && <UserProfile />}
                                </Route>

                                <Route path="/login">
                                    {user && <Redirect to="/" />}
                                    {!user && <Login />}
                                </Route>
                                <Route path="/signup">
                                    {user && <Redirect to="/" />}
                                    {!user && <Signup />}
                                </Route>
                            </Switch>
                        </div>
                    </BrowserRouter>
                )}
            </AppLayout>
        </div>
    );
}

export default App;
