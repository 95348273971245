import { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { PlusSmIcon } from "@heroicons/react/solid";

import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

import { Toaster, resolveValue } from "react-hot-toast";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

//! Todo: replace all nagivation with the dynamic objects below
const navigation = [
    { name: "Home", href: "#", current: true },
    { name: "Profile", href: "#", current: false },
    { name: "Resources", href: "#", current: false },
    { name: "Company Directory", href: "#", current: false },
    { name: "Openings", href: "#", current: false },
];
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
];
//! todo end!

const Navbar = () => {
    const { logout, isPending } = useLogout();
    const { user } = useAuthContext();

    return (
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <Toaster
                            toastOptions={{ style: { minWidth: "240px" } }}
                        />

                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="-ml-2 mr-2 flex items-center md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <MenuIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block lg:hidden h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                        alt="Workflow"
                                    />
                                    <img
                                        className="hidden lg:block h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden md:ml-6 md:flex md:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                                    <NavLink
                                        exact
                                        to="/"
                                        className={(isActive) =>
                                            classNames(
                                                isActive
                                                    ? "border-indigo-500 text-gray-900 "
                                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                            )
                                        }
                                    >
                                        <span>Dashboard</span>
                                    </NavLink>
                                    <NavLink
                                        exact
                                        to="/chat"
                                        className={(isActive) =>
                                            classNames(
                                                isActive
                                                    ? "border-indigo-500 text-gray-900 "
                                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                            )
                                        }
                                    >
                                        <span>Chat</span>
                                    </NavLink>

                                    <NavLink
                                        exact
                                        to="/team"
                                        className={(isActive) =>
                                            classNames(
                                                isActive
                                                    ? "border-indigo-500 text-gray-900 "
                                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                            )
                                        }
                                    >
                                        <span>Team</span>
                                    </NavLink>
                                    <NavLink
                                        exact
                                        to="/samples"
                                        className={(isActive) =>
                                            classNames(
                                                isActive
                                                    ? "border-indigo-500 text-gray-900 "
                                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                            )
                                        }
                                    >
                                        <span>Samples</span>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <button
                                        type="button"
                                        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <PlusSmIcon
                                            className="-ml-1 mr-2 h-5 w-5"
                                            aria-hidden="true"
                                        />
                                        <span>New Job</span>
                                    </button>
                                </div>
                                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                                    <button
                                        type="button"
                                        className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <span className="sr-only">
                                            View notifications
                                        </span>
                                        <BellIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                <span className="sr-only">
                                                    Open user menu
                                                </span>
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    referrerPolicy="no-referrer"
                                                    src={user.photoURL}
                                                    alt="avatar"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "block px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                        >
                                                            Your Profile
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "block px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                        >
                                                            Settings
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                {user && (
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={logout}
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100"
                                                                        : "",
                                                                    "block px-4 py-2 text-sm text-gray-700"
                                                                )}
                                                            >
                                                                Sign out
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                )}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <NavLink
                                exact
                                to="/"
                                className={(isActive) =>
                                    classNames(
                                        isActive
                                            ? "bg-indigo-50 border-indigo-500 text-indigo-700 "
                                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 ",
                                        " block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                                    )
                                }
                            >
                                <span>Dashboard</span>
                            </NavLink>
                            <NavLink
                                exact
                                to="/chat"
                                className={(isActive) =>
                                    classNames(
                                        isActive
                                            ? "bg-indigo-50 border-indigo-500 text-indigo-700 "
                                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 ",
                                        " block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                                    )
                                }
                            >
                                <span>Chat</span>
                            </NavLink>
                            <NavLink
                                exact
                                to="/team"
                                className={(isActive) =>
                                    classNames(
                                        isActive
                                            ? "bg-indigo-50 border-indigo-500 text-indigo-700 "
                                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 ",
                                        " block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                                    )
                                }
                            >
                                <span>Team</span>
                            </NavLink>
                            <NavLink
                                exact
                                to="/samples"
                                className={(isActive) =>
                                    classNames(
                                        isActive
                                            ? "bg-indigo-50 border-indigo-500 text-indigo-700 "
                                            : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 ",
                                        " block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                                    )
                                }
                            >
                                <span>Samples</span>
                            </NavLink>
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex items-center px-4 sm:px-6">
                                <div className="flex-shrink-0">
                                    <img
                                        className="h-10 w-10 rounded-full"
                                        src={user.photoURL}
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">
                                        {user.displayName}
                                    </div>
                                    <div className="text-sm font-medium text-gray-500">
                                        {user.email}
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="sr-only">
                                        View notifications
                                    </span>
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="mt-3 space-y-1">
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                                >
                                    Your Profile
                                </a>
                                <a
                                    href="#"
                                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                                >
                                    Settings
                                </a>
                                {!isPending && (
                                    <a
                                        href="#"
                                        onClick={logout}
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                                    >
                                        Sign out
                                    </a>
                                )}
                                {isPending && (
                                    <a
                                        href="#"
                                        disabled
                                        onClick={logout}
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                                    >
                                        Signing out...
                                    </a>
                                )}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Navbar;
