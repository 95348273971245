import { useState, useEffect } from "react";
import { useAuthContext } from "./useAuthContext";

// firebase imports
import {
    auth,
    storage,
    db,
    getGoogleProvider,
    timestamp,
    parseError,
} from "../firebase/config";
import {
    createUserWithEmailAndPassword,
    updateProfile,
    signInWithPopup,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email, password, displayName, thumbnail) => {
        setError(null);
        setIsPending(true);

        try {
            // signup
            const { user } = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );

            if (!user) {
                throw new Error("Could not complete signup");
            }

            // set storage path, upload image, and get downloadable link
            const storageRef = ref(
                storage,
                `thumbnails/${user.uid}/${thumbnail.name}`
            );
            await uploadBytes(storageRef, thumbnail);
            const imageUrl = await getDownloadURL(storageRef);

            // add display name and avatar URL to user
            await updateProfile(user, {
                displayName,
                photoURL: imageUrl,
            });

            // create user document, if not exists
            await setDoc(doc(db, "users", user.uid), {
                online: true,
                displayName,
                photoURL: imageUrl,
                email: email,
                lastOnline: timestamp,
                type: "email",
            });

            // dispatch login action
            dispatch({ type: "LOGIN", payload: user });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                setError(parseError(err.code));
                setIsPending(false);
            }
        }
    };

    const signupWithGoogle = async () => {
        setError(null);
        setIsPending(true);

        try {
            // provider
            const provider = getGoogleProvider();
            auth.useDeviceLanguage();

            // signup
            const { user } = await signInWithPopup(auth, provider);

            if (!user) {
                throw new Error("Could not complete signup");
            }

            // create user document, if not exists
            await setDoc(doc(db, "users", user.uid), {
                online: true,
                displayName: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                lastOnline: timestamp,
                type: "google",
            });

            // dispatch login action
            dispatch({ type: "LOGIN", payload: user });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                setError(parseError(err.code));
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { signup, signupWithGoogle, error, isPending };
};
