import React from "react";
import DynamicForm from "../../components/DynamicForm";
import LeftContent from "../../components/layouts/page/LeftContent";
import MainContent from "../../components/layouts/page/MainContent";
import { PageBannerDark } from "../../components/layouts/page/PageBanner";
import PageLayout from "../../components/layouts/page/PageLayout";

//todo this only a template. use return values from list to build a list of fields that can be passed to the dynamic form.
const profileFormFields = [
    { id: 1, label: "Name:", name: "name", placeholder: "John", size: "small" },
    {
        id: 2,
        label: "Surname:",
        name: "surname",
        placeholder: "Smith",
        size: "small",
    },
    {
        id: 3,
        label: "Email:",
        name: "email",
        placeholder: "jsmith@example.com",
        size: "large",
    },
];

const UserProfile = () => {
    const handleFormSubmit = (values) => {
        // handle form submitted here!
        console.log(values);
    };
    return (
        <>
            <PageBannerDark title="Manage Profile" />
            <PageLayout>
                <LeftContent></LeftContent>
                <MainContent>
                    <DynamicForm
                        headerText="User Profile"
                        detailText="Edit your basic profile detail."
                        inputItems={profileFormFields}
                        onSubmit={handleFormSubmit}
                    />
                </MainContent>
            </PageLayout>
        </>
    );
};

export default UserProfile;
