import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useCollection } from "../../../hooks/useCollection";
import ChatCreate from "./ChatCreate";
import ChatItem from "./ChatItem";
import ChatRoomHeader from "./ChatRoomHeader";

let dataQuery;
const ChatRoom = ({ room = { id: 1 } }) => {
    const { user } = useAuthContext();
    const [loadedAvatar, setLoadedAvatar] = useState(false);
    const bottomListRef = useRef();

    // get chat messages
    const { documents } = useCollection(
        "messages",
        "room",
        "==",
        room.id,
        ["createdAt", "asc"],
        100
    );

    const scrollToFooter = () => {
        //* Scroll down to the bottom of the list
        bottomListRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const handleMessageSent = (message) => {
        scrollToFooter();
    };

    useEffect(() => {
        scrollToFooter();
    }, [room.id]);

    return (
        <>
            <div className="flex flex-col">
                <ChatRoomHeader {...room} />

                <div className="flex-1 scrollable-view ">
                    {documents &&
                        documents.map((chat) => (
                            <ChatItem
                                key={chat.id}
                                detail={chat}
                                currentUser={user.uid}
                            />
                        ))}
                    <div className="mt-8" ref={bottomListRef} />
                </div>
            </div>
            <ChatCreate room={room} onMessageSent={handleMessageSent} />
        </>
    );
};

export default ChatRoom;
