import { NavLink } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";

import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";

//import deleteIcon from "../assets/delete-icon.svg";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const navLinks = [{ name: "Dashboard", link: "/", current: true }];
const navLinksBACKUPS = [
    { name: "Dashboard", link: "/", current: true },
    { name: "Chat", link: "/chat", current: false },
    { name: "Team", link: "/team", current: false },
    { name: "Samples", link: "/samples", current: false },
];

const userLinks = [
    { name: "Your Profile", link: "/profile" },
    { name: "Settings", link: "/settings" },
];

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const TopNavbar = () => {
    const { logout, isPending } = useLogout();
    const { user } = useAuthContext();
    return (
        <>
            {user && (
                <Disclosure as="nav" className="bg-gray-800">
                    {({ open }) => (
                        <>
                            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                                <div className="border-b border-gray-700">
                                    <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                                        {/* logo and main menu */}
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="h-8 w-8"
                                                    src="/logo_blue_64.png"
                                                    alt="Workflow"
                                                />
                                            </div>
                                            <div className="hidden md:block">
                                                <div className="ml-10 flex items-baseline space-x-4">
                                                    {navLinks.map((item) => (
                                                        <NavLink
                                                            exact
                                                            key={item.name}
                                                            to={item.link}
                                                            className={(
                                                                isActive
                                                            ) =>
                                                                classNames(
                                                                    isActive
                                                                        ? "bg-gray-900 text-white"
                                                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                                    "px-3 py-2 rounded-md text-sm font-medium"
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                {item.name}
                                                            </span>
                                                        </NavLink>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {/* notification and profile */}
                                        <div className="hidden md:block">
                                            <div className="ml-4 flex items-center md:ml-6">
                                                <button
                                                    type="button"
                                                    className="bg-gray-800 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                                >
                                                    <span className="sr-only">
                                                        View notifications
                                                    </span>
                                                    <BellIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>

                                                {/* Profile dropdown */}
                                                <Menu
                                                    as="div"
                                                    className="ml-3 relative"
                                                >
                                                    <div>
                                                        <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                            <span className="sr-only">
                                                                Open user menu
                                                            </span>
                                                            <img
                                                                className="h-8 w-8 rounded-full"
                                                                referrerPolicy="no-referrer"
                                                                src={
                                                                    user.photoURL
                                                                }
                                                                alt=""
                                                            />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg px-1 py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            {userLinks.map(
                                                                (item) => (
                                                                    <Menu.Item
                                                                        key={
                                                                            item.name
                                                                        }
                                                                    >
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <NavLink
                                                                                exact
                                                                                key={
                                                                                    item.name
                                                                                }
                                                                                to={
                                                                                    item.link
                                                                                }
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100"
                                                                                        : "",
                                                                                    "block rounded-md px-2 py-2 text-sm text-gray-700"
                                                                                )}
                                                                            >
                                                                                {/* <a
                                                                                href={
                                                                                    item.link
                                                                                }
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100"
                                                                                        : "",
                                                                                    "block rounded-md px-2 py-2 text-sm text-gray-700"
                                                                                )}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </a> */}{" "}
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </NavLink>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            )}
                                                            <Menu.Item key="logout">
                                                                {({
                                                                    active,
                                                                }) => (
                                                                    <a
                                                                        href="#"
                                                                        onClick={
                                                                            logout
                                                                        }
                                                                        className={classNames(
                                                                            active
                                                                                ? "bg-gray-100"
                                                                                : "",
                                                                            "block rounded-md px-2 py-2 text-sm text-gray-700"
                                                                        )}
                                                                    >
                                                                        Sign out
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>

                                        {/* Mobile menu button */}
                                        <div className="-mr-2 flex md:hidden">
                                            <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                <span className="sr-only">
                                                    Open main menu
                                                </span>
                                                {open ? (
                                                    <XIcon
                                                        className="block h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    <MenuIcon
                                                        className="block h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Mobile menu */}
                            <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                                <div className="px-2 py-3 space-y-1 sm:px-3">
                                    {navLinks.map((item) => (
                                        <NavLink
                                            exact
                                            key={item.name}
                                            to={item.link}
                                            className={(isActive) =>
                                                classNames(
                                                    isActive
                                                        ? "bg-gray-900 text-white"
                                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                    "block px-3 py-2 rounded-md text-base font-medium"
                                                )
                                            }
                                        >
                                            <span>{item.name}</span>
                                        </NavLink>
                                    ))}
                                </div>
                                <div className="pt-4 pb-3 border-t border-gray-700">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                referrerPolicy="no-referrer"
                                                src={user.photoURL}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium leading-none text-white">
                                                {user.displayName}
                                            </div>
                                            <div className="text-sm font-medium leading-none text-gray-400">
                                                {user.email}
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="ml-auto bg-gray-800 flex-shrink-0 p-1 text-gray-400 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                        >
                                            <span className="sr-only">
                                                View notifications
                                            </span>
                                            <BellIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userLinks.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.link}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                {item.name}
                                            </a>
                                        ))}

                                        {!isPending && (
                                            <a
                                                href="#"
                                                onClick={logout}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                Sign out
                                            </a>
                                        )}
                                        {isPending && (
                                            <a
                                                href="#"
                                                disabled
                                                onClick={logout}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                            >
                                                Signing out...
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            )}
        </>
    );
};

export default TopNavbar;

{
    /* 
================
NOTE: For use later!
================
<NavLayout>
<Logo />
<MainMenu navLinks={navigationLinks} />
</NavLayout> 

*/
}
