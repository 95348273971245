import { useState, useEffect } from "react";
import { useAuthContext } from "./useAuthContext";

// firebase imports
import {
    auth,
    db,
    timestamp,
    getGoogleProvider,
    parseError,
} from "../firebase/config";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const useLogin = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const login = async (email, password) => {
        setError(null);
        setIsPending(true);

        try {
            // login
            const { user } = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );

            // set online status for current user
            await updateDoc(doc(db, "users", user.uid), {
                online: true,
                lastOnline: timestamp,
            });

            // dispatch login action
            dispatch({ type: "LOGIN", payload: user });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                setError(parseError(err.code));
                setIsPending(false);
            }
        }
    };

    const loginWithGoogle = async () => {
        setError(null);
        setIsPending(true);

        try {
            // provider
            const provider = getGoogleProvider();
            auth.useDeviceLanguage();

            // login
            const { user } = await signInWithPopup(auth, provider);

            // get user document data
            const userDocument = await getDoc(doc(db, "users", user.uid));

            //* has this google account signed in without registering?
            if (userDocument?.exists()) {
                //* update current user session detail
                await updateDoc(doc(db, "users", user.uid), {
                    online: true,
                    lastOnline: timestamp,
                });
            } else {
                //* create user document for first time signin
                await setDoc(doc(db, "users", user.uid), {
                    online: true,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    email: user.email,
                    lastOnline: timestamp,
                    type: "google",
                });
            }

            // dispatch login action
            dispatch({ type: "LOGIN", payload: user });

            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            if (!isCancelled) {
                setError(parseError(err.code));
                setIsPending(false);
            }
        }
    };

    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { login, loginWithGoogle, isPending, error };
};
