function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const RoomItem = ({ room, onClick, children }) => {
    const handleOnClick = (room) => {
        onClick(room);
    };

    return (
        <li
            onClick={(e) => handleOnClick(room)}
            className="col-span-1 flex shadow-sm rounded-md mb-2"
        >
            <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md ">
                {/* 
                    NOTE: to keep each tile the same height, include 
                    'truncate' in below className. but this then makes 
                    tile extend beyond visible area for very long text. 
                */}
                <div className="flex-1 px-4 py-2 text-sm ">
                    <a className="text-gray-900 font-medium hover:text-gray-600">
                        {room.name}
                    </a>
                    <p className="text-gray-500">{room.uname}</p>
                </div>
                <div className="flex-shrink-0 pr-2">{children}</div>
            </div>
        </li>
    );
};

export default RoomItem;
