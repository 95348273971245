import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
} from "@heroicons/react/outline";
import { XIcon, LightningBoltIcon } from "@heroicons/react/solid";

import { useToastContext } from "../hooks/useToastContext";
import useTimeout from "../hooks/useTimeout";

const ToastItem = ({ toast }) => {
    const { dispatch, closeToast } = useToastContext();

    // todo: make the auto-dismiss optional for toast. Make large and small toasts. can be used for informational messages such as when they click on something a help message can be shown

    //* react friendly timeout hook.
    useTimeout(() => {
        dispatch({
            type: "REMOVE",
            payload: { id: toast.id },
        });
    }, 1800);

    return (
        <Transition
            show={toast.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="hover:shadow-none translate-y-0 hover:translate-y-1 transition-all duration-300 ease-in-out max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                {/* original class without smooth anitmation ___
                 ___ max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black 
                 ___ ring-opacity-5 overflow-hidden*/}
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            {toast.type === "info" && (
                                <InformationCircleIcon
                                    className="h-6 w-6 text-blue-500"
                                    aria-hidden="true"
                                />
                            )}

                            {toast.type === "success" && (
                                <CheckCircleIcon
                                    className="h-6 w-6 text-green-500"
                                    aria-hidden="true"
                                />
                            )}
                            {toast.type === "error" && (
                                <ExclamationCircleIcon
                                    className="h-6 w-6 text-red-500"
                                    aria-hidden="true"
                                />
                            )}
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">
                                {toast.content.title}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                                {toast.content.description}
                            </p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                            <button
                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => closeToast(toast.id)}
                            >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
};

export default ToastItem;
