import React, { createContext, useReducer } from "react";
import { createPortal } from "react-dom";
import ToastContainer from "../components/ToastContainer";

export const ToastContext = createContext();

export const toastReducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return [
                ...state,
                {
                    id: Math.random().toString().substring(2),
                    content: action.payload.content,
                    type: action.payload.type,
                    show: true,
                },
            ];
        case "REMOVE":
            return state.filter((t) => t.id !== action.payload.id);
        case "REMOVE_ALL":
            return [];
        default:
            return state;
    }
};

export const ToastContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(toastReducer, []);

    //! 📕📙📗📘📓⚠️🛑
    console.log("📙 ToastContext state:", state);

    return (
        <ToastContext.Provider value={{ ...state, dispatch }}>
            {children}

            {createPortal(<ToastContainer toasts={state} />, document.body)}
        </ToastContext.Provider>
    );
};
