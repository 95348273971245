import { useEffect, useState } from "react";

const useModal = () => {
    const [selected, setSelected] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const openModal = (object) => {
        setSelected(object);
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelected(null);
        setIsOpen(false);
    };

    useEffect(() => {
        //* init actions here.
    }, []);

    return {
        isOpen,
        openModal,
        closeModal,
        selected,
    };
};

export default useModal;
