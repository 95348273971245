const AppLayout = ({ children }) => {
    return (
        <>
            {/* Background color split screen for large screens */}
            <div
                className="fixed top-0 left-0 w-1/2 h-full bg-white"
                aria-hidden="true"
            />
            <div
                className="fixed top-0 right-0 w-1/2 h-full bg-white"
                aria-hidden="true"
            />
            <div className="relative min-h-screen flex flex-col">
                {children}
            </div>
        </>
    );
};

export default AppLayout;
