import FancyList from "../../components/FancyList";

const Samples = () => {
    return (
        <>
            <div className="py-10">
                <header>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold leading-tight text-gray-900">
                            Samples Page
                        </h1>
                    </div>
                </header>
                <main>
                    <div className="max-w-3xl mx-auto px-4 py-8 sm:px-6 lg:max-w-7xl lg:px-8">
                        <h1 className="sr-only">Page title</h1>
                        {/* Main 3 column grid */}
                        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                            {/* Left column */}
                            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                                <section aria-labelledby="section-1-title">
                                    <h2
                                        className="sr-only"
                                        id="section-1-title"
                                    >
                                        Section title
                                    </h2>
                                    <div className="rounded-lg bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            {/* Start your content */}
                                            <div className="sm:px-0">
                                                <div className="border-4 border-dashed border-gray-200 rounded-lg h-96 flex justify-center items-center ">
                                                    <FancyList className="" />
                                                </div>
                                            </div>
                                            {/* End replace with your content */}
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/* Right column */}
                            <div className="grid grid-cols-1 gap-4">
                                <section aria-labelledby="section-2-title">
                                    <h2
                                        className="sr-only"
                                        id="section-2-title"
                                    >
                                        Section title
                                    </h2>
                                    <div className="rounded-lg bg-white overflow-hidden shadow">
                                        <div className="p-6">
                                            {/* Start your content */}
                                            <div className="sm:px-0">
                                                <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
                                            </div>
                                            {/* End replace with your content */}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Samples;
