import "./PageLayout.css";

const PageLayout = ({ children }) => {
    return (
        <>
            {/* 3 column wrapper */}

            <div className="flex-grow w-full h-screen max-w-7xl mx-auto  xl:px-8 lg:flex">
                <div className="flex-1 min-w-0 bg-white xl:flex ">
                    {children}
                </div>
            </div>
        </>
    );
};

export default PageLayout;
