import { useEffect, useState } from "react";
import LeftContent from "../../components/layouts/page/LeftContent";
import MainContent from "../../components/layouts/page/MainContent";
import { PageBannerDark } from "../../components/layouts/page/PageBanner";
import PageLayout from "../../components/layouts/page/PageLayout";
import ModalConfirm from "../../components/ModalConfirm";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCollection } from "../../hooks/useCollection";
import { useFirestore } from "../../hooks/useFirestore";
import useModal from "../../hooks/useModal";
import { useToastContext } from "../../hooks/useToastContext";

import RoomItem from "./ChatRooms/RoomItem";
import RoomList from "./ChatRooms/RoomList";
import RoomCreate from "./ChatRooms/RoomCreate";
import RoomActions from "./ChatRooms/RoomActions";
import Modal from "../../components/Modal";
import ChatRoom from "./ChatRooms/ChatRoom";
import ChatCreate from "./ChatRooms/ChatCreate";

const Dashboard = () => {
    const { user } = useAuthContext();
    const { showSuccessToast } = useToastContext();
    const [selectedRoom, setSelectedRoom] = useState(null);
    const { documents: rooms } = useCollection("rooms", null, null, null, [
        "createdAt",
        "desc",
    ]);
    const { deleteDocument, response } = useFirestore("rooms");
    const { isOpen, openModal, closeModal, selected } = useModal();

    //! delete modal actions
    const handleDeleteCancel = () => {
        closeModal();
    };
    const handleDeleteOk = () => {
        closeModal();
        //* selected is set when openModal is called from child component.
        deleteDocument(selected.id);
    };
    //! delete modal actions end

    const handleRoomClick = (room) => {
        // console.log(room);
        setSelectedRoom(room);
    };

    useEffect(() => {
        //console.log(user);
    }, []);
    useEffect(() => {
        //* only show notification if the delete is successful.
        if (response?.success) {
            showSuccessToast("Chat room deleted!");
        }
    }, [response.success]);

    return (
        <>
            <PageBannerDark title="Dasboard" />
            <PageLayout>
                <LeftContent>
                    <RoomCreate />
                    <RoomList>
                        {rooms &&
                            rooms.map((room) => (
                                <RoomItem
                                    key={room.id}
                                    room={room}
                                    onClick={handleRoomClick}
                                >
                                    {user.uid === room.uid && (
                                        <RoomActions
                                            room={room}
                                            deleteAction={openModal}
                                        />
                                    )}
                                </RoomItem>
                            ))}
                    </RoomList>
                </LeftContent>
                <MainContent>
                    {selectedRoom && (
                        <>
                            <ChatRoom room={selectedRoom}></ChatRoom>
                        </>
                    )}
                    {/* <ChatRoom room={selectedRoom}></ChatRoom> */}
                </MainContent>
            </PageLayout>

            <ModalConfirm
                isOpen={isOpen}
                title={`Delete room?`}
                onCancel={handleDeleteCancel}
                onOk={handleDeleteOk}
            />
        </>
    );
};

export default Dashboard;
