import { formatRelative } from "date-fns";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";

const formatDate = (date) => {
    let formattedDate = "";
    if (date) {
        // Convert the date in words relative to the current date
        formattedDate = formatRelative(date, new Date());
        // Uppercase the first letter
        formattedDate =
            formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }
    return formattedDate;
};

const ChatItem = ({ detail, currentUser }) => {
    const [loadedAvatar, setLoadedAvatar] = useState(false);

    useEffect(() => {}, []);

    return (
        <>
            {currentUser === detail.uid && (
                <div className="message me mb-4 flex text-right">
                    <div className="flex-1 px-2">
                        <div className="inline-block bg-sky-500 rounded-md p-2 px-6 text-white">
                            <span>{detail.message}</span>
                        </div>
                        <div className="pr-4">
                            <small className="text-gray-500">
                                {" "}
                                {detail.createdAt &&
                                    formatDate(
                                        new Date(
                                            detail.createdAt.seconds * 1000
                                        )
                                    )}
                            </small>
                        </div>
                    </div>
                </div>
            )}
            {currentUser !== detail.uid && (
                <div className="message mb-4 flex">
                    <div
                        className={`flex-2 transition-opacity ease-in  ${
                            loadedAvatar ? "opacity-100" : "opacity-0"
                        }`}
                    >
                        <div className="w-12 h-12 relative">
                            <img
                                className="w-12 h-12 rounded-full mx-auto"
                                referrerPolicy="no-referrer"
                                src={detail.photoURL}
                                onLoad={(e) => setLoadedAvatar(true)}
                                alt="chat-user"
                            />
                            {/* <span className="absolute w-4 h-4 bg-gray-400 rounded-full right-0 bottom-0 border-2 border-white"></span> */}
                        </div>
                    </div>
                    <div className="flex-1 px-2">
                        <div className="inline-block bg-gray-300 rounded-md p-2 px-6 text-gray-700">
                            <span>{detail.message}</span>
                        </div>
                        <div className="pl-4">
                            <small className="text-gray-500">
                                {" "}
                                {detail.createdAt &&
                                    formatDate(
                                        new Date(
                                            detail.createdAt.seconds * 1000
                                        )
                                    )}
                            </small>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatItem;
