const MainContent = ({ children }) => {
    return (
        <>
            <div className="bg-white lg:min-w-0 lg:flex-1">
                <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                    {/* Start main area*/}
                    <div
                        className="relative h-full min-h-screen"
                        style={{ minHeight: "36rem" }}
                    >
                        <div className="overflow-y-auto inset-0 border-0 border-gray-200 border-dashed">
                            {/* add .absolute back to enable scrollbar. and keep content always in page*/}
                            {children}
                        </div>
                    </div>
                    {/* End main area */}
                </div>
            </div>
        </>
    );
};

export default MainContent;
