import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { ToastContextProvider } from "./context/ToastContext";

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <ToastContextProvider>
                <App />
            </ToastContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

/*
Rollback from React 18 to React 17
==================================
https://stackoverflow.com/questions/46566830/how-to-use-create-react-app-with-an-older-react-version
https://github.com/facebook/create-react-app/discussions/9917
https://bobbyhadz.com/blog/react-module-not-found-cant-resolve-react-dom
*/
