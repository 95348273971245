import { useEffect, useState } from "react";

const ChatRoomHeader = ({ name, uname, photoURL }) => {
    const [loadedAvatar, setLoadedAvatar] = useState(false);

    useEffect(() => {
        //only reset when new photo found.
        setLoadedAvatar(false);
    }, [photoURL]);

    return (
        <>
            <div className="px-0 pt-0 pb-1 mb-2 flex justify-between items-center border-b">
                <div className="flex">
                    <div
                        className={`transition-opacity ease-in  ${
                            loadedAvatar ? "opacity-100" : "opacity-0"
                        }`}
                    >
                        <div className="mr-4 relative">
                            <img
                                className="w-12 h-12 rounded-full "
                                referrerPolicy="no-referrer"
                                src={photoURL}
                                onLoad={(e) => setLoadedAvatar(true)}
                                alt="chat-user"
                            />
                        </div>
                    </div>
                    <div>
                        <h2 className="text-lg font-medium text-gray-900">
                            {name}
                        </h2>
                        <p className="text-gray-500">{uname}</p>
                    </div>
                </div>

                <div className="right-content">
                    {
                        //todo add custom actions here on the right side of panel
                    }
                </div>
            </div>
        </>
    );
};

export default ChatRoomHeader;
