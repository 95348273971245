import { useEffect, useRef, useState } from "react";

// firebase imports
import { db, parseError } from "../firebase/config";
import {
    collection,
    onSnapshot,
    query,
    orderBy,
    where,
    limit,
} from "firebase/firestore";

export const useCollection = (
    col,
    _where,
    _operator,
    _value,
    _o,
    _limit = 25
) => {
    const [documents, setDocuments] = useState(null);
    const [error, setError] = useState(null);

    // if we don't use a ref --> infinite loop in useEffect
    // _order is an array and is "different" on every function call
    const _order = useRef(_o).current;

    useEffect(() => {
        let ref = collection(db, col);

        // console.log(_where);

        // console.log(_operator);
        // console.log(_value);

        if (_where) {
            ref = query(ref, where(_where, _operator, _value));
        }
        if (_order) {
            ref = query(ref, orderBy(..._order));
        }

        // always limit the data
        ref = query(ref, limit(_limit));

        // setup realtime listener
        const unsubscribe = onSnapshot(
            ref,
            (snapshot) => {
                let results = [];
                snapshot.docs.forEach((doc) => {
                    results.push({
                        id: doc.id,
                        ...doc.data(),
                        isDelivered: !doc.metadata.hasPendingWrites,
                    });
                });

                // update state
                setDocuments(results);
                setError(null);
            },
            (err) => {
                setError(parseError(err.code));
                console.log(err);
            }
        );

        // cleanup function when effect unmounts
        return () => unsubscribe();
    }, [col, _where, _operator, _value, _order, _limit]);

    return { documents, error };
};

// ! Use this hook to retrieve a a list of documents from Firebase.

/*
Operators:
==========
< less than
<= less than or equal to
== equal to
> greater than
>= greater than or equal to
!= not equal to
array-contains
array-contains-any
in
not-in

USAGE:
=====

const { documents } = useCollection("messages", 
                            ["owner","==","Malbury"], 
                            ["createdAt","desc"]);

Onsnapshot Metadata:
====================
hasPendingWrites:false=> means that the document has been synced with the fb server.
doc.metadata.hasPendingWrites,


Retrieve and use Sub-collection:
================================
NOTE: review for possible generic capability later.
  
  const messagesCol = collection(firestore, 'supportCases', caseId, 'messages')
  const messageQuery = query(messagesCol, orderBy('timestamp'))
  const stream = (callback) => onSnapshot(messageQuery, snapshot => {
    const messages = snapshot.docs.map(doc => {
      const isDelivered = !doc.metadata.hasPendingWrites;
      return {
        isDelivered,
        id: doc.id,
        ...doc.data()
      };
    })


*/
