import { useEffect, useState } from "react";
import ToastItem from "./ToastItem";

const ToastContainer = ({ toasts }) => {
    //* placeholder
    useEffect(() => {}, [toasts]);
    return (
        <>
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    {toasts &&
                        toasts.map((t) => <ToastItem key={t.id} toast={t} />)}
                </div>
            </div>
        </>
    );
};

export default ToastContainer;
