import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD2vvL0yuJgRqLVNio_CyX489q3AzRTetU",
    authDomain: "chat-rooms-7af2f.firebaseapp.com",
    projectId: "chat-rooms-7af2f",
    storageBucket: "chat-rooms-7af2f.appspot.com",
    messagingSenderId: "73651174441",
    appId: "1:73651174441:web:3956d4a601a2ad2152f038",
};

// init firebase
const app = initializeApp(firebaseConfig);

// init firebase auth
const auth = getAuth(app);

// init firestore
const db = getFirestore(app);

// init file storage
const storage = getStorage(app);

//timstamp
const timestamp = serverTimestamp();

// helper functions
const getGoogleProvider = () => new GoogleAuthProvider();
const getFacebookProvider = () => new FacebookAuthProvider();

// error messages
const parseError = (errCode) => {
    switch (errCode) {
        case "auth/user-not-found":
            return "Unable to find this account";
        case "auth/wrong-password":
            return "Invalid username or password";
        case "auth/invalid-email":
            return "Invalid email address";
        default:
            return "An error occurred";
    }
    //todo ERROR_CODES:  https://firebase.google.com/docs/auth/admin/errors
};

export {
    auth,
    db,
    storage,
    timestamp,
    getGoogleProvider,
    getFacebookProvider,
    parseError,
};
