import { useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/outline";

const DynamicForm = ({
    inputItems = [],
    headerText = "",
    detailText = "",
    submitButtonText = "Submit",
    onSubmit = () => {},
}) => {
    const [inputValues, setInputValues] = useState({});
    const [formActive, setFormActive] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormActive(false);
        onSubmit(inputValues);
    };
    const onChange = (itemId) => {
        return (e) => {
            setInputValues({ ...inputValues, [itemId]: e.target.value });
        };
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="p-1">
                <div className="bg-white shadow sm:rounded-md sm:overflow-hidden">
                    {formActive && (
                        <div className="form">
                            <div className=" py-6 px-4 space-y-6 sm:p-6">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        {headerText}
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">
                                        {detailText}
                                    </p>
                                </div>

                                <div className="grid grid-cols-6 gap-6">
                                    {inputItems.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`col-span-6 ${
                                                item.size == "small"
                                                    ? "sm:col-span-3"
                                                    : ""
                                            }`}
                                        >
                                            <label
                                                htmlFor={`input-${item.name}`}
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {item.label}
                                            </label>
                                            <input
                                                type="text"
                                                name={`input-${item.name}`}
                                                id={`input-${item.name}`}
                                                placeholder={item.placeholder}
                                                onChange={onChange(item.id)}
                                                value={
                                                    inputValues[item.id] || ""
                                                }
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <button
                                    type="submit"
                                    className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    {submitButtonText}
                                </button>
                            </div>
                        </div>
                    )}
                    {!formActive && (
                        <div
                            className="flex items-center justify-center content-center  py-6 px-4 space-y-6 sm:p-6 sm:rounded-md sm:overflow-hidden"
                            style={{ minHeight: "18rem" }}
                        >
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mx-auto h-12 w-12 text-emerald-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                                <span className="mt-2 block text-lg font-medium text-gray-900">
                                    Form submitted. Thank you!
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default DynamicForm;
