import { useEffect, useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import { PlusSmIcon, ChatIcon, ChatAltIcon } from "@heroicons/react/solid";
import useModal from "../../../hooks/useModal";
import Modal from "../../../components/Modal";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useToastContext } from "../../../hooks/useToastContext";

const RoomCreateFull = ({ submitForm, user }) => {
    const { addDocument, response } = useFirestore("rooms");

    // save new chat room
    const handleSubmit = (e) => {
        e.preventDefault();

        addDocument({
            name: room,
            photoURL: user.photoURL,
            active: false,
            type: "group",
        });
    };

    useEffect(() => {
        // reset if create was successful
        if (response?.success) {
            setRoom("");
        }
    }, [response.success]);
    const [room, setRoom] = useState("");
    return (
        <div className="px-0 pt-0 pb-4">
            <h2 className="text-lg font-medium text-gray-900">Chat Rooms</h2>
            <p className="mt-1 text-sm text-gray-600">
                Available rooms to have a chat
            </p>
            <form className="mt-2 flex space-x-3" onSubmit={submitForm}>
                <div className="flex-1 min-w-0">
                    <label htmlFor="roomname" className="sr-only">
                        Enter name
                    </label>
                    <div className="relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="roomname"
                            id="roomname"
                            required
                            className="focus:border-indigo-700  block w-full  sm:text-sm border-gray-300 rounded-md"
                            placeholder="Enter name"
                            onChange={(e) => setRoom(e.target.value)}
                            value={room}
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusSmIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                    />
                    <span>New</span>
                </button>
            </form>
        </div>
    );
};
const RoomCreateMin = ({ openNew }) => {
    return (
        <>
            <div className="px-0 pt-0 pb-4 flex justify-between items-center">
                <h2 className="text-lg font-medium text-gray-900">
                    Conversations
                </h2>
                <div>
                    <ChatAltIcon
                        className="-ml-1 mr-0 h-6 w-6 text-gray-400 hover:w-7 hover:h-7 hover:text-emerald-500 transition-all duration-300 ease-in-out hover:cursor-pointer"
                        onClick={(e) => {
                            openNew();
                        }}
                        aria-hidden="true"
                    />
                </div>
            </div>
        </>
    );
};

const RoomCreate = () => {
    const { addDocument, response } = useFirestore("rooms");
    const { user } = useAuthContext();
    const { isOpen, openModal, closeModal, selected } = useModal();
    const { showSuccessToast } = useToastContext();
    const [roomName, setRoomName] = useState("");

    // save new conversation
    const handleNewOk = () => {
        //e.preventDefault();

        addDocument({
            name: roomName,
            photoURL: user.photoURL,
            active: false,
            type: "group",
            members: [user.uid],
        });

        closeModal();
    };

    const handleNewCancel = () => {
        closeModal();
    };

    useEffect(() => {
        //* save successfull, show notication
        if (response.success) {
            showSuccessToast("New conversation created!");
        }
        //* reset the room name
        setRoomName("");
    }, [response]);
    return (
        <>
            <RoomCreateMin openNew={openModal} />
            <Modal
                isOpen={isOpen}
                onCancel={handleNewCancel}
                onOk={handleNewOk}
            >
                <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left mb-3">
                        <h3
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                        >
                            New Conversation
                        </h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                What would you like to call your new
                                conversation?
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700"
                            ></label>
                            <input
                                type="text"
                                name="room-name"
                                id="room-name"
                                autoComplete="given-name"
                                onChange={(e) => setRoomName(e.target.value)}
                                value={roomName}
                                placeholder="Ex. Xmas party ideas"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RoomCreate;
