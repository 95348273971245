const PageBanner = ({ title }) => {
    return (
        <>
            <header className="bg-white shadow-sm z-0">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-gray-900">
                        {title}
                    </h1>
                </div>
            </header>
        </>
    );
};
const PageBannerDark = ({ title }) => {
    return (
        <>
            <header className="bg-gray-800 shadow-sm z-0">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-lg leading-6 font-semibold text-white">
                        {title}
                    </h1>
                </div>
            </header>
        </>
    );
};

export { PageBanner, PageBannerDark };
