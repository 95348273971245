//styles
import "./LeftContent.css";

const LeftContent = ({ children }) => {
    return (
        <>
            <div className="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
                <div className="h-full pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                    {/* Start left column area */}
                    <div
                        className="h-full relative"
                        style={{ minHeight: "12rem" }}
                    >
                        <div className="overflow-y-auto absolute inset-0 border-0 border-gray-200 border-dashed rounded-lg">
                            {/* START : content goes here! */}
                            {children}

                            {/* END : content goes here! */}
                        </div>
                    </div>
                    {/* End left column area */}
                </div>
            </div>
        </>
    );
};

export default LeftContent;
