const RoomList = ({ children }) => {
    return (
        <div>
            <ul
                role="list"
                className="mt-3 pr-1  gap-2 scrollable-view scrollable-xl-stop "
            >
                {children}
            </ul>
        </div>
    );
};

export default RoomList;
